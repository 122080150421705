import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'storage';
import {
  AUTH_ENDPOINT,
  AUTH_URL,
  AuthModel,
  DEFAULT_AUTH_ENDPOINT,
  DEFAULT_AUTH_URL,
  DEFAULT_TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
} from './auth.type';
import { ApiService } from 'api';
import { LoadingService } from 'loading';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authUrl: string;
  public authEndpoint: string;
  public tokenStorageKey: string;

  public router = inject(Router);
  public storage = inject(StorageService);
  public apiService = inject(ApiService);
  public loadingService = inject(LoadingService);
  private injector = inject(Injector);

  constructor() {
    this.authUrl = this.injector.get(AUTH_URL, DEFAULT_AUTH_URL);
    this.authEndpoint = this.injector.get(AUTH_ENDPOINT, DEFAULT_AUTH_ENDPOINT);
    this.tokenStorageKey = this.injector.get(
      TOKEN_STORAGE_KEY,
      DEFAULT_TOKEN_STORAGE_KEY,
    );
  }

  public async login(data: AuthModel) {
    const response = await this.apiService.post(this.authEndpoint, data, {
      isAuthentication: false,
    });

    this.storage.set(this.tokenStorageKey, response.token);

    return response;
  }

  public logout() {
    this.storage.remove(this.tokenStorageKey);
    this.router.navigate(['/', this.authUrl]);
    this.loadingService.clear();
  }

  public isAuthenticated(redirectTo: string): boolean {
    const hasAuthToken = !!this.getAuthToken();

    if (!hasAuthToken) {
      this.router.navigate([redirectTo]);
    }

    return hasAuthToken;
  }

  public isUnauthenticated(redirectTo: string): boolean {
    const hasAuthToken = !!this.getAuthToken();

    if (hasAuthToken) {
      this.router.navigate([redirectTo]);
    }

    return !hasAuthToken;
  }

  private getAuthToken(): string | null {
    return this.storage.get(this.tokenStorageKey);
  }
}
