import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export function authenticated(redirectTo: string): CanActivateFn {
  return () => inject(AuthService).isAuthenticated(redirectTo);
}

export function unauthenticated(redirectTo: string): CanActivateFn {
  return () => inject(AuthService).isUnauthenticated(redirectTo);
}
