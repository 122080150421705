import { OnDestroy, Pipe, inject, type PipeTransform } from '@angular/core';
import { ApiService, RequestOptions } from 'api';
import { Subject, from, map, takeUntil } from 'rxjs';

@Pipe({
  name: 'secureFile',
  standalone: true,
})
export class SecureFilePipe implements PipeTransform, OnDestroy {
  public apiService = inject(ApiService);
  private unsubscribe = new Subject();
  private objectUrl?: string;

  transform(url: string, options?: RequestOptions) {
    return from(this.apiService.getSecureFile(url, options)).pipe(
      map((url) => {
        if (this.objectUrl) {
          window.URL.revokeObjectURL(this.objectUrl);
        }        

        this.objectUrl = url;

        return this.objectUrl;
      }),
      takeUntil(this.unsubscribe),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(this.unsubscribe);
    this.unsubscribe.complete();

    if (this.objectUrl) {
      window.URL.revokeObjectURL(this.objectUrl);
    }
  }
}
