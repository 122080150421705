import { isDevMode } from '@angular/core';

const env = {
  production: isDevMode(),
  apiURL: 'https://monorepo-api.vudo.tech',
  apiClockerURL: 'https://stam.vudo.tech/logistics-api/clocker/v2/',
  apiAdminUniformURL: 'https://admin.uniform.stam.vudo.tech',
  websocketConfig: {
    cluster: 'mt1',
    wsHost: 'monorepo-socket.vudo.tech',
    wsPort: 6001,
    wssPort: 443,
    disableStats: true,
    channelAuthorization: {
      transport: 'ajax',
      endpoint: 'https://monorepo-api.vudo.tech/laravel-websockets/auth',
      headers: {
        'X-App-ID': 'local',
      },
    },
    enabledTransports: ['ws', 'wss'],
    forceTLS: false,
  },
  apps: {
    Logistics: 'https://stam.vudo.tech/logistics/',
    Staff: 'https://stam.vudo.tech/staff/',
    HR: 'https://stam.vudo.tech/hr/',
    Access: 'https://stam.vudo.tech/access/',
    Client: 'https://stam.vudo.tech/client/',
    Contractor: 'https://stam.vudo.tech/contractors/',
    Forms: 'https://stam.vudo.tech/forms/',
    Logger: 'https://stam.vudo.tech/logger',
    Payroll: 'https://stam.vudo.tech/payroll/',
    Saleshub: 'https://stam.vudo.tech/saleshub/',
    SIA: 'https://stam.vudo.tech/sia/',
    Supplier: 'https://stam.vudo.tech/supplier/',
    Clocker: 'https://clocker.stam.vudo.tech/',
    Question: 'https://question.stam.vudo.tech/',
    AdminUniform: 'https://admin.uniform.stam.vudo.tech/',
    AdminQuestion: 'https://admin.question.stam.vudo.tech',
    Admin: 'https://monorepo.vudo.tech/',
    Uniform: 'https://uniform.stam.vudo.tech/',
    ID: 'https://id.stam.vudo.tech/',
    QR: 'https://qr.stam.vudo.tech/',
    CMS: 'https://group.vudo.tech/cms/',
    Web: 'https://group.vudo.tech/',
    Deployment: 'https://deployment.stam.vudo.tech',
  },
};
export default env;