import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage = localStorage;

  get(key: string) {
    const value = this.storage.getItem(key);

    if (!value) return value;

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  set(key: string, value: unknown) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
