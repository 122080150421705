/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Injector } from '@angular/core';
import { API_URL, RequestOptions } from './api.type';
import { StorageService } from 'storage';
import { DEFAULT_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from 'auth';
import env from 'env';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public apiURL!: string;
  public tokenStorageKey!: string;

  constructor(
    public storage: StorageService,
    private injector: Injector,
  ) {
    this.apiURL = this.injector.get(API_URL, env.apiURL);
    this.tokenStorageKey = this.injector.get(
      TOKEN_STORAGE_KEY,
      DEFAULT_TOKEN_STORAGE_KEY,
    );
  }

  get(endpoint: string, options?: RequestOptions) {
    return fetch(this.initURL(endpoint, options?.query), {
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
    }).then(async (response) => {
      const data = await response.json();

      if (!response.ok) throw data;

      return data;
    });
  }

  downloadFile(endpoint: string, fileName: string, options?: RequestOptions) {
    return fetch(this.initURL(endpoint, options?.query), {
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
    }).then(async (response) => {
      if (!response.ok) {
        const data = await response.json();
        throw data;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);

      return blob;
    });
  }

  post(endpoint: string, body: Record<string, any>, options?: RequestOptions) {
    return fetch(this.initURL(endpoint, options?.query), {
      method: 'POST',
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
      body: JSON.stringify(body),
    }).then(async (response) => {
      const data = await response.json();

      if (!response.ok) throw data;

      return data;
    });
  }

  postFormData(
    endpoint: string,
    body: Record<string, any>,
    options?: RequestOptions,
  ) {
    const formData = new FormData();

    for (const key in body) {
      formData.append(key, body[key]);
    }

    const headers = this.getHeaders(
      options?.headers,
      options?.isAuthentication,
    );
    delete headers['Content-Type'];

    return fetch(this.initURL(endpoint, options?.query), {
      method: 'POST',
      headers,
      body: formData,
    }).then(async (response) => {
      const data = await response.json();

      if (!response.ok) throw data;

      return data;
    });
  }

  put(endpoint: string, body: Record<string, any>, options?: RequestOptions) {
    return fetch(this.initURL(endpoint, options?.query), {
      method: 'PUT',
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
      body: JSON.stringify(body),
    }).then(async (response) => {
      const data = await response.json();

      if (!response.ok) throw data;

      return data;
    });
  }

  delete(endpoint: string, options?: RequestOptions) {
    return fetch(this.initURL(endpoint, options?.query), {
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
      method: 'DELETE',
    }).then(async (response) => {
      const data = await response.json();

      if (!response.ok) throw data;

      return data;
    });
  }

  getSecureFile(url: string, options?: RequestOptions) {
    return fetch(this.initURL(url, options?.query), {
      headers: this.getHeaders(options?.headers, options?.isAuthentication),
    }).then(async (response) => {
      if (!response.ok) {
        const data = await response.json();
        throw data;
      }

      const blob = await response.blob();

      return window.URL.createObjectURL(blob);
    });
  }

  initURL(endpoint: string, query?: Record<string, string> | string) {
    const url = new URL(
      /http(s?):\/\//gi.test(endpoint)
        ? endpoint
        : `${this.apiURL}/api/${endpoint}`,
    );

    if (query) {
      url.search = new URLSearchParams(query).toString();
    }

    return url;
  }

  getHeaders(
    headersInit: Record<string, string> = {},
    isAuthentication = true,
  ) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...headersInit,
    };

    if (isAuthentication) {
      headers['Authorization'] = this.getAuthorization();
    }

    return headers;
  }

  getAuthorization() {
    return `Bearer ${this.getAuthToken()}`;
  }

  getAuthToken(): string | null {
    return this.storage.get(this.tokenStorageKey);
  }
}
