/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from 'api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public apiService = inject(ApiService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Check if the request URL matches any of the protected URLs
    if (request.url.startsWith(this.apiService.apiURL)) {
      // Clone the request and add the Authorization header
      request = request.clone({
        setHeaders: {
          Authorization: this.apiService.getAuthorization(),
        },
      });
    }

    // Proceed with the request
    return next.handle(request);
  }
}
