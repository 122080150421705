import { Injectable, computed, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly queue = signal([] as number[]);
  public readonly isLoading = computed(() => this.queue().length > 0);

  public show() {
    this.queue.update((data) => [...data, Date.now()]);
  }

  public hide() {
    this.queue.update((data) => data.slice(1));
  }

  public clear() {
    this.queue.set([]);
  }
}
