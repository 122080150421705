import { InjectionToken } from '@angular/core';

export const DEFAULT_TOKEN_STORAGE_KEY = 'token';
export const DEFAULT_AUTH_ENDPOINT = 'auth/login';
export const DEFAULT_AUTH_URL = 'auth';

export const AUTH_URL = new InjectionToken<string>('AUTH_URL');
export const AUTH_ENDPOINT = new InjectionToken<string>('AUTH_ENDPOINT');
export const TOKEN_STORAGE_KEY = new InjectionToken<string>(
  'TOKEN_STORAGE_KEY',
);

export type AuthType = 'admin' | 'staff' | 'supplier';

export interface AuthModel {
  email: string;
  password: string;
}

export interface AuthResponse {
  token: string;
  user?: object;
  job_id?: string;
  job_name?: string;
}
